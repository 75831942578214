const params = {
	action: 'myfilter_annuaire',
	mots: [],
	category: [],
	search: '',
	post_type: jQuery('.cpt').val()
};

$('#submit-filter-annuaire').on('click', function(e) {
	e.preventDefault;

	var filter = $('#filter-annuaires');
	var loading = $('.load-bar');

	$.ajax({
		url: filter.attr('action'),
		data: params,
		type: 'POST',
		action: 'ajax_function_annuaire',
		beforeSend: function() {
			$('.annuaires').html('');
			loading.toggleClass('invisible');
		},
		success: function(data) {
			loading.toggleClass('invisible');
			$('.annuaires').html(data);
		}
	});
	return false;
});

jQuery('#terms').change(function() {
	params['search'] = jQuery('#terms').val();
});

jQuery('.list_category input').change(function() {
	var $input = jQuery(this);
	var key = 'category';

	if (
		$input.prop('checked') &&
		params.hasOwnProperty(key) &&
		!params[key].includes($input.val())
	) {
		params[key].push($input.val());
	} else {
		params[key].splice(params[key].indexOf($input.val()), 1);
	}
	console.log(params);
});
jQuery('select').change(function() {
	var key = 'category';
	var $select = jQuery(this);
	console.log($select.val());

	if (params.hasOwnProperty(key) && !params[key].includes($select.val())) {
		params[key].push($select.val());
	} else {
		params[key].splice(params[key].indexOf($select.val()), 1);
	}
	console.log(params);
});
jQuery('.popular-category').each(function() {
	var label = $(this).find($('.selectit'));
	var input = $(this).find($('input'));
	var inputId = input.attr('id');
	label.attr('for', inputId);
	input.insertBefore(label);
});

jQuery('.list_category input').each(function() {
	jQuery(this).prop('disabled', false);
});

jQuery('.mots li').each(function() {
	var mot = jQuery(this);
	mot.on('click', function() {
		mot.toggleClass('selected');
		if (
			mot.hasClass('selected') &&
			params.hasOwnProperty('mots') &&
			!params['mots'].includes(mot.data('letter'))
		) {
			params['mots'].push(mot.data('letter'));
		} else {
			params['mots'].splice(params['mots'].indexOf(mot.data('letter')), 1);
		}
		console.log(params);
	});
});
